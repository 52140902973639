import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from "@material-ui/core/MenuItem";
import styles from '../../Assets/CSS/stylesheet_UHS'
import { Select } from "@material-ui/core";

const CustomTextField = withStyles(
    styles.prgTextField1
)(TextField);

class ProgCommonDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '', errorText: this.props.helperMsg, isValid: false }

    }
    setValue = (event) => {
        console.log('eveveveeve ', event.target.value);
        let txtVal = event.target.value;
        txtVal = txtVal.trimLeft();
        this.setState({ errorText: '', value: txtVal, isValid: false });
        this.props.setValue(txtVal, true, this.props.parent);
    }


    render() {
        console.log('comm, ', this.props.isProgram, ' - ',);
        return (
            <CustomTextField
                select
                required={false}
                label={this.props.label}
                sx={{ color: "transparent" }}
                name={this.props.name}
                value={this.props.value}
                onChange={this.setValue}
                style={styles.prgDropDown}
                helperText={(this.props.value === '' || this.state.isValid) ? this.state.errorText : ''}
                error={this.state.isValid}
                variant="filled">
                {this.props.List.map((option, index) => (
                    <MenuItem key={index} value={option} selected>
                        {option == "THS" ? "Thrive Programs" : option == "Prosper" ? "Prosper Programs" : "UHS Standard Programs"}
                    </MenuItem>
                ))}
            </CustomTextField>

        );
    }
}

export default ProgCommonDropDown;


