import React, { Component } from 'react';
import customeClasses from './Eligibility.css';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker} from '@material-ui/pickers';
import axios from 'axios';
import configuration from '../../../configurations';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Loader from '../../loader';
import CommonSelect from "../../CommonScreens/CommonSelect";
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import Sample from "../../CommonScreens/sampleTextField";
import i18n from '../../../i18next';
import CommonTable from "../../CommonScreens/commonTable";
import Grid from '@material-ui/core/Grid';
import { Modal } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import ForumIcon from "@material-ui/icons/Forum";
import Fab from "@material-ui/core/Fab";
import {Auth} from "aws-amplify";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styles = props => (
       customStyle.chkEligiScreen
);

const PurpleRadio = withStyles(
    customStyle.radioBtn
)(props => <Radio color="default" {...props} />);

const WizardButton = withStyles(
    customStyle.viewBtn
)(Button);

const ProceedButton = withStyles(
    customStyle.proceedBtn
)(Button);

const CustomTextField = withStyles(
    customStyle.textField,
)(TextField);

const CrudButton = withStyles(
    customStyle.crudBtn,
)(Fab);

const CustomeTextField = withStyles({
    root: {
        '& .MuiFilledInput-root': {
            backgroundColor: '#f8f8f8',
            color: '#19191d',
            fontSize: '16px',
            lineHeight: '24px',
            height: '56px',
            margin: '0px',
            '&:hover': {
                backgroundColor: '#f4f4f4',
                color: '#533278'
            }
        },
        '&:not(.Mui-disabled):hover::before':{
            borderBottom:'red'
        },
        '&:not(.Mui-disabled):hover::after':{
            borderBottom:'purple'
        },
    
        '& .Mui-focused': {
            backgroundColor: '#ffffff',
            color: '#19191d',
          
        },
        '& label.Mui-focused': {
            color: '#533278',
        },
        overridesUnderline:{
            '&:hover:not($disabled):not($error):not($focused):before':{
                borderBottomColor: '#cdcde7',
            }
        },
        disabled:{},
        error: {},
        focused: {},
    }
})(TextField);

class Eligibility extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            progress: 1,
            isValid: true,
            disablePrev: false,
            checkedB: true,
            radio: '',
            input: '',
            select: '',
            check: '',
            value:'',
            setSelectedDate:'',
            selectedDate:'',
            date: new Date() ,
            questions: [
                {
                    id: 1,
                    question: '',
                    ans: '',
                    type: 'textbox',
                    subType: 'text',
                    subTitle: [],
                    relatedQuestions: []
                }
            ],
            questionData: [],
            answerList: [],
            membersList: [],
            loaderShow: false,
            name:'',
            formErrors: { Physician: '', diagnosis: '', },
            Physician:'',
            diagnosis:'',
            disQualifiedData:[],
            disqual:[],
            dateOfTreatment:false,
            dateOfTrtmt:false,
            allQueAnswred : false,
            bmiData : [],
            submitValid : false,
            backValid : false,
            disQuailifyModal : false,
            optReasonList: [],
            modalOpen: false,
            optReason: '',
            otherReason: '',
            formValid : false,
            disOtReason : false,
            errorText : '',
            isValidopt : false,
            msgModal : false,
            isAgent:false
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        let cookiesData = JSON.parse(sessionStorage.getItem('STATE_PARAM'))
        if(cookiesData && cookiesData.isAgent){
            this.setState({
                isAgent : true
            })
        }
        sessionStorage.setItem('current_screen', '3');
        let subId = JSON.parse(localStorage.getItem('CurrentLoginUser')).id;
        this.setState({
            loaderShow: true,
            progress:this.state.progress+14.28
        })

        //get All members
        fetch(configuration.baseUrl + '/enrollment/getMemberBySubId/' + subId)
            .then((response) => response.json())
            .then(membersResult => {
                if (membersResult.response) {
                    if(this._isMounted) {
                    this.setState({
                        membersList: membersResult.response
                    });
                }
            }
            });

        //get ans given by member
        axios.get(configuration.baseUrl + '/questionbank/getMemberAns/' + subId)
            .then(responseAns => {
                if (responseAns && responseAns.data) {
                    let answers = [];
                    let answerData = responseAns.data.response.questionAnswer ? responseAns.data.response.questionAnswer : [];
                    /*let bmiData = responseAns.data.response.bmiList;*/

                    //question
                    fetch(configuration.baseUrl + '/questionbank/getQuestions/' + sessionStorage.getItem('CLIENT_ID'))
                        .then((response) => response.json())
                        .then(response => {
                            if (response.response) {
                                let validCount = 0;
                               /* for(let i=0; i<bmiData.length; i++){
                                    if((bmiData[i].feet === 0 || bmiData[i].feet) && (bmiData[i].inches === 0 || bmiData[i].inches) && bmiData[i].weight){
                                        validCount++;
                                    }
                                }*/
                                /*if(answerData.length === response.response.length){ /!*&& validCount === bmiData.length*!/
                                    this.state.allQueAnswred = true;
                                }*/


                                for (let i = 0; i < response.response.length; i++) {
                                    let result = answerData ? answerData.find(obj => obj.questionId === response.response[i].question.id) : {};
                                    let relatedQuestionAns = [];
                                    for (let j = 0; j < response.response[i].question.relatedQuestions.length; j++) {
                                        let relatedSubQuestionAns = [];
                                        let relatedQuestionsResult = result && result.relatedQuestionAns ? result.relatedQuestionAns.find(obj => obj.relatedQuestionId === response.response[i].question.relatedQuestions[j].id) : [];
                                        for (let k = 0; k < response.response[i].question.relatedQuestions[j].relatedSubQuestions.length; k++) {
                                            let relatedSubQuestionsResult = relatedQuestionsResult && relatedQuestionsResult.relatedSubQuestionAns ? relatedQuestionsResult.relatedSubQuestionAns.find(obj => obj.relatedSubQuestionId === response.response[i].question.relatedQuestions[j].relatedSubQuestions[k].id) : [];
                                            let a=new Date();
                                            relatedSubQuestionAns.push({
                                                relatedSubQuestionId: response.response[i].question.relatedQuestions[j].relatedSubQuestions[k].id,
                                                answer: relatedSubQuestionsResult && relatedSubQuestionsResult.answer ? relatedSubQuestionsResult.answer : (response.response[i].question.relatedQuestions[j].relatedSubQuestions[k].subType === 'date' ? new Date() : ''),
                                                optionId: relatedSubQuestionsResult ? relatedSubQuestionsResult.optionId : ''
                                            });
                                            
                                        }

                                        let memArr = relatedQuestionsResult && relatedQuestionsResult.answer ? relatedQuestionsResult.answer.split(',') : [];
                                        let valArr = [];
                                        if(memArr.length > 0){
                                            for(let i=0; i<memArr.length; i++){
                                                let findIndex = this.state.membersList.findIndex(obj => obj.id.toString() === memArr[i]);
                                                if(findIndex > -1){
                                                    valArr.push(memArr[i]);
                                                }
                                            }
                                        }

                                        relatedQuestionAns.push({
                                            relatedQuestionId: response.response[i].question.relatedQuestions[j].id,
                                            answer: valArr,
                                            optionId: relatedQuestionsResult ? relatedQuestionsResult.optionId : '',
                                            relatedSubQuestionAns: relatedSubQuestionAns
                                        });
                                    }
                                    answers.push({
                                        questionId: response.response[i].question.id,
                                        optionId: result && result.optionId ? result.optionId : '',
                                        answer: result && result.answer ? result.answer : '',
                                        relatedQuestionAns: relatedQuestionAns
                                    });
                                }

                                //BMI question login for surcharges
                                /*let BMI_DATA = [];
                                for(let j=0; j<this.state.membersList.length; j++){
                                    let obj = {};
                                    let findIndex = bmiData.findIndex(obj => obj.id === this.state.membersList[j].id);
                                    if(findIndex > -1){
                                        obj.feet = (bmiData[j].feet === 0 || bmiData[j].feet) ? bmiData[j].feet : '';
                                        obj.inches = (bmiData[j].inches === 0 || bmiData[j].inches) ? bmiData[j].inches : '';
                                        obj.weight = bmiData[j].weight ? bmiData[j].weight : '';
                                    } else {
                                        obj.feet = '';
                                        obj.inches = '';
                                        obj.weight = '';
                                    }

                                    obj.id = this.state.membersList[j].id ? bmiData[j].id : '';
                                    obj.name = this.state.membersList[j].firstName + ' ' + this.state.membersList[j].lastName;

                                    let count = 0;
                                    Object.keys(obj).map( (key,index) => {
                                        if(parseInt(obj.feet) === 0 && parseInt(obj.inches) === 0){
                                            obj.heightValid = false;
                                        } else if(obj.feet === '' && obj.inches === ''){
                                            obj.heightValid = false;
                                        } else {
                                            obj.heightValid = true;
                                        }
                                    });
                                    BMI_DATA.push(obj);
                                }*/

                                if(this._isMounted) {
                                    this.setState({
                                        questions: response.response,
                                        answerList: answers,
                                        loaderShow: false,
                                        /*bmiData : BMI_DATA*/
                                    }, () => {
                                        this.isAllValidHandler();
                                        this.enableSubmit()
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(error => {
                console.log(error);
            })
            
    }
    reduceProgress = () => {
        if (this.state.count > 0) {
            this.setState({
                count: this.state.count - 1,
                progress: (this.state.count ) / this.state.questions.length * 100,
                dateOfTreatment:false
            }, () => {
                this.isAllValidHandler();
                this.enableSubmit()
            });
        }
    }

    onClickBack=(event)=>{
        for (let i = 0; i < this.state.answerList.length; i++) {
            for (let j = 0; j < this.state.answerList[i].relatedQuestionAns.length; j++) {
                if(this.state.answerList[i].relatedQuestionAns[j].answer && !Array.isArray(this.state.answerList[i].relatedQuestionAns[j].answer)){
                    //this.state.answerList[i].relatedQuestionAns[j].answer = this.state.answerList[i].relatedQuestionAns[j].answer.toString();
                    this.state.answerList[i].relatedQuestionAns[j].answer = this.state.answerList[i].relatedQuestionAns[j].answer ? this.state.answerList[i].relatedQuestionAns[j].answer.split(',') : [];
                }
            }
        }
        let count = 0;
        if(this.state.count === this.state.questions.length - 1){
            count = this.state.count;
        } else {
            count = this.state.count + 1
        }
        let progress = ((count) / this.state.questions.length * 100)  + 14.28;
        this.setState({
                disqualified : false,
                count : count,
                progress : progress
        },() => {
            this.isAllValidHandler();
            this.enableSubmit()
        }) 

    }


    increaseProgress = () => {

        this.setState({
            loaderShow : false
        });

        if(this.state.answerList[this.state.count].answer === 'No'){
            this.handleContinue('NEXT');
        } else if(this.state.answerList[this.state.count].answer === 'Yes' && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0){
            if(this.state.questions[this.state.count].question.code === 'SMO'){
                this.handleContinue('NEXT');
            } else {
                this.setState({
                    disQuailifyModal : true
                });
            }
        }
    }

    multiselectAnswerChangeHandler = (event, key, index) => {
        
            if(event.target.checked){
                
                for (let i = 0 ; i < this.state.membersList.length; i++) {
                    
                    if (key.id === this.state.membersList[i].id ) {
                            if(!Array.isArray(this.state.answerList[this.state.count].relatedQuestionAns[index].answer)){
                                this.state.answerList[this.state.count].relatedQuestionAns[index].answer = [];
                                this.state.answerList[this.state.count].relatedQuestionAns[index].answer.push(this.state.membersList[i].id.toString());
                            } else {
                                this.state.answerList[this.state.count].relatedQuestionAns[index].answer.push(this.state.membersList[i].id.toString());
                            }
                    }

                }  
            } else {  
                let findIndex = this.state.answerList[this.state.count].relatedQuestionAns[index].answer.indexOf(key.id.toString());
                if(findIndex > -1 ){
                    this.state.answerList[this.state.count].relatedQuestionAns[index].answer.splice(findIndex, 1);
                    
                }
            }   
       //let flag = this.state.answerList[this.state.count].relatedQuestionAns[index].answer.length > 0 ? true : false;
        this.setState({
            refresh:  true
        }, () => {
            this.isAllValidHandler();
            this.enableSubmit()
        });
       
    }
    

    isAllValidHandler = (name,value) => {
        let flag = false;

        if (this.state.answerList[this.state.count].answer !== '' && this.state.answerList[this.state.count].answer === 'Yes') {
            if (this.state.answerList[this.state.count].relatedQuestionAns[0] && this.state.answerList[this.state.count].relatedQuestionAns[0].relatedSubQuestionAns && this.state.answerList[this.state.count].relatedQuestionAns[0].relatedSubQuestionAns.length === 0) {
                if(this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0){
                    let data = this.state.answerList[this.state.count].relatedQuestionAns[0].answer;
                    var found;
                    for(let i=0; i< data.length; i++){
                        let ind = this.state.membersList.findIndex((obj)=> obj.id == data[i]);
                        found = ind;
                    }
                    if((found && found > 0) || (found === 0)){
                        flag = true;
                    } else {
                        flag = false;
                    }
                } else {
                    flag = false;
                }
            } else {
                let check = true;
                if (this.state.answerList[this.state.count].relatedQuestionAns[0] && this.state.answerList[this.state.count].relatedQuestionAns[0].relatedSubQuestionAns) {
                    for (let i = 0; i < this.state.answerList[this.state.count].relatedQuestionAns[0].relatedSubQuestionAns.length; i++) {
                        if (this.state.answerList[this.state.count].relatedQuestionAns[0].relatedSubQuestionAns[i].answer && this.state.answerList[this.state.count].relatedQuestionAns[0].relatedSubQuestionAns[i].answer.length !== '') {
                            check = true;
                        } else {
                            check = false;
                            break;
                        }
                    }
                }
                flag = check;
            }
        } else if (this.state.answerList[this.state.count].answer === '') {
            flag = false;
        } else {
            flag = true;
        }
        if(this.state.count === this.state.questions.length-1){
            this.setState({
                isValid: false
            }, () => {
                this.enableSubmit()
            });
        } else {
            this.setState({
                isValid: flag
            }, () => {
                this.enableSubmit()
            });
        }


    }

    handlerCopy(e){
        e.preventDefault();
    }

    textAnswerChangeHandler = (val,itemValid,parentDetails) => {
        if(itemValid){
            this.state.answerList[this.state.count].relatedQuestionAns[parentDetails.index].relatedSubQuestionAns[parentDetails.subindex].answer = val;
            
            this.setState({
                refresh: true
            }, () => {
                this.isAllValidHandler(parentDetails.name,val);

            });
        }else{
            this.state.answerList[this.state.count].relatedQuestionAns[parentDetails.index].relatedSubQuestionAns[parentDetails.subindex].answer = '';

            this.setState({
                refresh: true,
                isValid:false
            }, () => {
                this.enableSubmit()
            });
        }

    }
    answerChangeHandler = (event, name, optionId) => {
        if (name === 'check') {
            this.state.answerList[this.state.count].answer = event.target.checked;
            this.state.answerList[this.state.count].optionId = optionId;
        } else if (name === 'radio' || name === 'dropdown') {
            this.state.answerList[this.state.count].answer = event.target.value;
            for (let i = 0; i < this.state.questions[this.state.count].options.length; i++) {
                if (this.state.questions[this.state.count].options[i].option === event.target.value) {
                    this.state.answerList[this.state.count].optionId = this.state.questions[this.state.count].options[i].id;
                }
            }

            if(event.target.value === 'No'){
                this.state.answerList[this.state.count].relatedQuestionAns[0].answer = [];
            }
        } else {
            this.state.answerList[this.state.count].answer = event.target.value;
            this.state.answerList[this.state.count].optionId = optionId;
        }
        this.setState({
            refresh: true
        }, () => {
            this.isAllValidHandler();
            this.enableSubmit();
        });
    }

    handleDateChange = (date, index, subindex) => {
        this.state.answerList[this.state.count].relatedQuestionAns[index].relatedSubQuestionAns[subindex].answer = new Date(date);
        this.setState({
            refresh: true,
            dateOfTreatment:true
        }, () => {
            this.isAllValidHandler();
        });
    };




     submitAnswers = () => {
         this.setState({
             loaderShow: true
         });


         axios.get(configuration.baseUrl + '/questionbank/saveNoMember/' +JSON.parse(localStorage.getItem('CurrentLoginUser')).id )
             .then(response => {
                 if(this.state.answerList[this.state.count].answer === 'No'){
                     this.handleContinue('SUBMIT');
                 } else if(this.state.answerList[this.state.count].answer === 'Yes' && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0){
                     if(this.state.questions[this.state.count].question.code === 'SMO'){
                         this.handleContinue('SUBMIT')
                     } else {
                         this.setState({
                             disQuailifyModal : true,
                             loaderShow: false
                         });
                     }
                 }
             });
        /*for (let i = 0; i < this.state.answerList.length; i++) {
            for (let j = 0; j < this.state.answerList[i].relatedQuestionAns.length; j++) {
                this.state.answerList[i].relatedQuestionAns[j].answer = this.state.answerList[i].relatedQuestionAns[j].answer.toString();
            }
        }
       
        const data = {
            id: localStorage.getItem('memberId'),
            subId: JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
            memberQuestionAnswers: this.state.answerList,
            /!*bmiList : this.state.bmiData*!/
        };
        if(this._isMounted) {
        this.setState({
            loaderShow: true,
        })
    }
        axios.post(configuration.baseUrl + '/questionbank/saveMemberAnswer', data)
            .then(response => {
                if(this._isMounted) {
                this.checkQualified();
                }
            })
            .catch(error => {
                console.log(error);
            })*/


        /*let obj;
        obj.bmiList  = this.state.bmiData;*/
        // if(this._isMounted) {
        /*this.setState({
            loaderShow: true,
        });
        // }
        axios.post(configuration.baseUrl + '/questionbank/saveMemberBMIList', this.state.bmiData)
            .then(response => {
                if(this._isMounted) {
                this.checkQualified();
                }
            })
            .catch(error => {
                console.log(error);
            })*/

    }

    checkQualified(){
        let subId = JSON.parse(localStorage.getItem('CurrentLoginUser')).id;
        axios.get(configuration.baseUrl+'/questionbank/getEligibleMember/'+subId)
            .then(response=>{
                if(response.data.response){
                    if(this._isMounted) {
                    if(response.data.response.data && response.data.response.data.length > 0){
                        
                        this.setState({
                            disQualifiedData:response.data.response,
                            loaderShow: false,
                            disqualified:true,
                            disqual:response.data.reason
                        });
                    
                    }else{
                        this.setState({
                            loaderShow: false,
                        }, () => {
                            this.props.onClick();
                        });
                    }
                }
                }else{
                    this.setState({
                        loaderShow: false,
                    }, () => {
                        this.props.onClick();
                    });
                }

            })
            .catch(error => {
                this.setState({
                    loaderShow: false,
                });
            })

            
    }

    fourthMethod(e) {
        const re = /^[0-9]*$/;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
      }
      firstMethod(e) {
        const re = /^[a-zA-Z]*$/;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
      }


    textChangeHandler = (val,valid,details) => {
        if (valid) {
            this.state.bmiData[details.index][details.name] = val;
            if(details.name === 'feet' || details.name === 'inches'){
                if((parseInt(this.state.bmiData[details.index].feet) === 0) && parseInt(this.state.bmiData[details.index].inches) === 0){
                    let evt = new CustomEvent('feet_eligibility',{detail : {flag:true, parentData : details, value : val, index : details.index}});
                    window.dispatchEvent(evt);
                    this.state.bmiData[details.index].heightValid = false;
                } else if(this.state.bmiData[details.index].feet !== "" && this.state.bmiData[details.index].inches !== ""){
                    let evt = new CustomEvent('feet_eligibility',{detail : {flag:false, parentData : details, value : val, index : details.index}});
                    window.dispatchEvent(evt);
                    this.state.bmiData[details.index].heightValid = true;
                }
            }
        }else if(details){
            this.state.bmiData[details.index][details.name] = '';
        }
        this.setState({
            refresh: true
        }, () => {
            this.enableSubmit()
        }) ;
    }

    enableSubmit = () => {
        /*if(this.state.count === this.state.questions.length-1){
            if(this.state.questions[this.state.count].question.type === 'form'){
                let count = 0;
                for(let i=0; i<this.state.bmiData.length; i++){
                    if(this.state.bmiData[i].heightValid && this.state.bmiData[i].weight && (this.state.bmiData[i].feet === 0 || this.state.bmiData[i].feet) && (this.state.bmiData[i].inches === 0 || this.state.bmiData[i].inches)){
                        count++;
                    }
                }

                if(count === this.state.bmiData.length){
                    this.setState({
                        submitValid : false,
                        backValid : false
                    });   
                } else {
                    this.setState({
                        submitValid : true,
                        backValid : true
                    });
                }
            } else {
                this.setState({
                    submitValid : true,
                    backValid : true
                });
            }
        } else*/
        if(this.state.count !== this.state.questions.length){
            if(this.state.answerList[this.state.count] && this.state.answerList[this.state.count].answer){
                if(this.state.answerList[this.state.count].answer === 'Yes'){
                    if(this.state.answerList[this.state.count].relatedQuestionAns[0].answer && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0){
                        if(this.state.allQueAnswred){
                            this.setState({
                                submitValid : false,
                                backValid : false
                            });
                        } else if(this.state.count === this.state.questions.length - 1){
                            this.setState({
                                submitValid : false,
                                backValid : false
                            });
                        } else {
                            this.setState({
                                submitValid : true,
                                backValid : false
                            });
                        }

                    } else {
                        this.setState({
                            submitValid : true,
                            backValid : true
                        });
                    }
                } else if(this.state.answerList[this.state.count].answer === 'No'){
                    if(this.state.allQueAnswred){
                        this.setState({
                            submitValid : false,
                            backValid : false
                        });
                    } else if(this.state.count === this.state.questions.length - 1){
                        this.setState({
                            submitValid : false,
                            backValid : false
                        });
                    } else {
                        this.setState({
                            submitValid : true,
                            backValid : false
                        });
                    }
                } else {
                    this.setState({
                        submitValid : true,
                        backValid : true
                    });
                }
            } else {
                this.setState({
                    submitValid : true,
                    backValid : true
                });
            }
        } else if(this.state.allQueAnswred){
            this.setState({
                submitValid : true,
                backValid : false
            });
        } else {
            this.setState({
                submitValid : true,
                backValid : true
            });
        }
    };

    handleContinue = (flag) =>{
        this.setState({
            loaderShow : true
        });
        let subId = JSON.parse(localStorage.getItem('CurrentLoginUser')).id;
        let idObj = this.state.membersList.find(obj => obj.subId == subId);
        let obj = new Object();
        obj.subId = subId;
        obj.id = idObj.id;
        obj.memberQuestionAnswers = [];

        let ans = JSON.parse(JSON.stringify(this.state.answerList[this.state.count]));

        for(let i=0; i< ans.relatedQuestionAns.length; i++){
            ans.relatedQuestionAns[i].answer = ans.relatedQuestionAns[i].answer.toString();
        }
        obj.memberQuestionAnswers.push(ans);
        axios.post(configuration.baseUrl+'/questionbank/saveMemberEligibiltyAnswer', obj)
            .then(response=>{
                if(response.data.code === 200){
                    let count = 0;
                    if(this.state.count === this.state.questions.length-1){
                        count = this.state.count;
                    } else {
                        count = this.state.count + 1;
                    }

                    if(flag === 'SUBMIT'){
                        if(this._isMounted) {
                            this.checkQualified();
                        }
                    } else if (flag === 'NEXT' || flag === 'BUTTON') {
                        this.setState({
                            disQuailifyModal : false,
                            count : count,
                            progress: (this.state.count + 2) / this.state.questions.length * 100,
                            loaderShow : false
                        }, () => {
                            this.isAllValidHandler();
                            this.enableSubmit();
                        });
                    } else {
                        if(this._isMounted) {
                            this.checkQualified();
                        }
                    }
                }
            })
            .catch(error => {
                this.setState({
                    loaderShow: false,
                });
            })
    };

    handleQuite = () =>{
        this.setState({
            loaderShow : true
        });
        let subId = JSON.parse(localStorage.getItem('CurrentLoginUser')).id;
        let idObj = this.state.membersList.find(obj => obj.subId == subId);
        let obj = new Object();
        obj.subId = subId;
        obj.id = idObj.id;
        obj.memberQuestionAnswers = [];

        let ans = JSON.parse(JSON.stringify(this.state.answerList[this.state.count]));

        for(let i=0; i< ans.relatedQuestionAns.length; i++){
            ans.relatedQuestionAns[i].answer = ans.relatedQuestionAns[i].answer.toString();
        }
        obj.memberQuestionAnswers.push(ans);
        axios.post(configuration.baseUrl+'/questionbank/saveMemberEligibiltyAnswer', obj)
            .then(response=>{
                if(response.data.code === 200){
                    this.setState({
                        loaderShow: true,
                        disQuailifyModal : false
                    }, this.checkQualified);
                }
            })
            .catch(error => {
                this.setState({
                    loaderShow: false,
                });
            })
    };

    hideModal = () => {
        this.setState({
            loaderShow: false,
            disQuailifyModal : false
        });
    };

    optOutFromEligibility = () =>{
        if(this.props.isAgent){
            this.setState({
                loaderShow: true
            })
            let currentScreen = sessionStorage.getItem('current_screen');
            axios.get(configuration.baseUrl + '/enrollment/saveCompletionStatus/' + JSON.parse(localStorage.getItem('CurrentLoginUser')).id + '/' + currentScreen)
                .then(response => {
                    if (response && response.data.code === 200) {
                        sessionStorage.removeItem('STATE_PARAM');
                        sessionStorage.removeItem('STATE_VAL');
                        sessionStorage.removeItem('CLIENT_ID');
                        sessionStorage.removeItem('CHAT_BOX_Id');
                        cookies.remove("STATE_PARAM", { path: '/' });
                        window.close();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            this.setState({
                modalOpen: true,
                loaderShow: true
            });
            fetch(configuration.baseUrl + '/enrollment/getOptoutReasons')
                .then((response) => response.json())
                .then(response => {
                    if (response.response) {
                        let opRes = '';
                        let disOtReason = false;
                        let arr = [];
                        let fondobj = response.response.find(obj => obj.reasondCode === 'Family member ineligible');
                        arr.push(fondobj);
                        if(fondobj){
                            opRes = fondobj.id;
                            disOtReason = false;
                        } else {
                            opRes = '';
                            disOtReason = true;
                        }
                        this.setState({
                            optReasonList: arr,
                            modalOpen: true,
                            optReason: opRes,
                            otherReason: '',
                            formValid : false,
                            loaderShow: false,
                            disOtReason : disOtReason,
                            isValidopt : false,
                            errorText : ''
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        modalOpen: true,
                        loaderShow: false,
                        optReason: '',
                        otherReason: '',
                        formValid : false,
                        disOtReason : true
                    });
                })
        }
    };

    optoutCancelHandler = () => {
        this.state.optReason = '';
        this.state.otherReason = '';
        this.state.modalOpen = false;
        this.state.formValid = false;
        this.setState({
            refresh: true,
            isValidopt : false,
            errorText : ''
        })
    }

    optoutSubmitHandler = () => {
        this.setState({
            loaderShow: true,
            modalOpen: false,
        });
        let data = {
            subId: JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
            optReason: this.state.optReason,
            otherReason: this.state.otherReason
        };
        axios.post(configuration.baseUrl + '/enrollment/saveMemberOptout', data)
            .then(response => {
                this.setState({
                    loaderShow: false,
                    msgModal : true
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    otherOptingReasonChangeHandler = (event,name) => {
        event.preventDefault();
        let txtVal = event.target.value;

        if(txtVal !== ''){
            if (txtVal.match(/^[a-zA-Z ]*$/)) {
                this.setState({ errorText: '', isValidopt : false, otherReason : txtVal, formValid : false});
            } else {
                this.setState({errorText : 'Please enter valid reason', isValidopt : true, otherReason : txtVal, formValid : true});
            }
        } else {
            this.setState({errorText : '', isValidopt : false, otherReason : txtVal, formValid : false});
        }
    }

    optingReasonChangeHandler = (event, name) => {
        let value = event.target.value;
        this.state[name] = value;
    }

    hideMsgModal = (event) =>{
        this.setState({
            msgModal : false,
            loaderShow : true
        });
        let currentScreen = sessionStorage.getItem('current_screen');
        axios.get(configuration.baseUrl + '/enrollment/saveCompletionStatus/' + JSON.parse(localStorage.getItem('CurrentLoginUser')).id + '/' + currentScreen)
            .then(response => {
                if (response && response.data.code === 200) {
                    Auth.signOut();
                    localStorage.clear();
                    sessionStorage.removeItem('STATE_PARAM');
                    sessionStorage.removeItem('STATE_VAL');
                    sessionStorage.removeItem('CLIENT_ID');
                    sessionStorage.removeItem('CHAT_BOX_Id');
                    localStorage.setItem('isLogged', false);
                    sessionStorage.setItem('isLogged', false);
                    cookies.remove("STATE_PARAM", { path: '/' });
                    window.location.href = '/login';
                }
            })
            .catch(error => {
                console.log(error);   
            });

    };


    render() {
        const { classes } = this.props;
        const { value }=this.state;
        const {selectedDate, setSelectedDate }= (new Date());
        let answerField, finishButton, finishLaterButton;
        if (this.state.questions[this.state.count].question.type === 'check') {
            answerField = this.state.questions[this.state.count].options.map((key, index) => (
                <div key={index}>0
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.answerList[this.state.count].answer}
                                        onChange={(event) => this.answerChangeHandler(event, 'check', key.id)}
                                        inputProps={{
                                            'aria-label': 'secondary checkbox',
                                        }}
                                        style={{ color: '#533278' }}></Checkbox>
                                }

                                label={key.option}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            ))
        } else if (this.state.questions[this.state.count].question.type === 'textbox') {
            if (this.state.questions[this.state.count].question.subType === 'text') {
                answerField = <div>
                   
                    <CustomeTextField
                        required
                        id="filled-required"
                        label="Enter Text"
                        value={this.state.answerList[this.state.count].answer}
                        style={customeClasses.textField}
                        variant="filled"
                        autoComplete="off"
                        onKeyPress={this.firstMethod}
                        onCopy={this.handlerCopy}
                        onPaste={this.handlerCopy}
                        onChange={(event) => this.answerChangeHandler(event, 'textbox', '')}
                    />
                             
                   </div>
            } else if (this.state.questions[this.state.count].question.subType === 'date') {
                answerField = <div >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="date-picker-inline"
                            label="Date picker inline"
                            onCopy={this.handlerCopy}
                            onPaste={this.handlerCopy}
                            style={customStyle.datePicker}
                            value={this.state.answerList[this.state.count].answer}
                            onChange={this.handleDateChange}
                        />
                        
                    </MuiPickersUtilsProvider>
                </div>
            } else if (this.state.questions[this.state.count].question.subType === 'number') {
                answerField = <div>
                    <CustomeTextField
                        required
                        id="filled-required"
                        label="Enter Text"
                        type="number"
                        value={this.state.answerList[this.state.count].answer}
                        style={customeClasses.textField}
                        variant="filled"
                        autoComplete="off"
                        onKeyPress={this.firstMethod}

                        onCopy={this.handlerCopy}
                        onPaste={this.handlerCopy}
                        onChange={(event) => this.answerChangeHandler(event, 'textbox', '')}
                    />
                </div>
            }
        } else if (this.state.questions[this.state.count].question.type === 'radio') {
            answerField = <div>
                <RadioGroup aria-label="gender" name="gender1" style={{ display: 'block' }} value={this.state.answerList[this.state.count].answer} onChange={(event) => this.answerChangeHandler(event, 'radio', '')}>
                    {
                        this.state.questions[this.state.count].options.map((key, index) => (
                            <FormControlLabel key={index} value={key.option} control={<PurpleRadio />} label={key.option} />
                        ))
                    }
                </RadioGroup>
            </div>
        } else if (this.state.questions[this.state.count].question.type === 'dropdown') {
            answerField = <div>
                <CustomeTextField
                    select
                    label="Select Value"
                    SelectProps={{
                        native: true
                    }}
                    variant="filled"
                    autoComplete="off"
                    onCopy={this.handlerCopy}
                    onPaste={this.handlerCopy}
                    style={customeClasses.selectField}
                    value={this.state.answerList[this.state.count].answer}
                    onChange={(event) => this.answerChangeHandler(event, 'dropdown', '')}
                >
                    <option value="">
                    </option>
                    {this.state.questions[this.state.count].options.map((key, index) => (
                        <option key={index} value={key.option}>
                            {key.option}
                        </option>
                    ))}
                </CustomeTextField>
            </div>
        } /*else if(this.state.questions[this.state.count].question.type === 'form' && this.state.questions[this.state.count].question.code === 'BMI'){
            answerField = this.state.bmiData.map((key, i) => (
                    <Grid xs={12} item={true} key={i} style={{width: '100%', display:'flex',flexDirection:'row'}}>
                        <Grid xs={4} item={true} style={customStyle.bmiName}>
                            {key.name}
                        </Grid>
                        <Grid xs={4} item={true} style={{display:'flex',flexDirection:'row'}}>
                            <div style={customStyle.EnrollNew2Height}>
                                <Sample setChild={this.textChangeHandler.bind(this)} reqFlag={true} name={'Height_Feet' + i} label={'Height (feet)'} value={this.state.bmiData[i].feet} disable={false} style={customStyle.textFieldWrp33} length={2}  fieldType={'feet'} errMsg={'Enter valid height'} helperMsg={'Height required'}  parentDetails={{name:'feet', index : i, id : key.id}}></Sample>
                            </div>
                            <div style={customStyle.EnrollNew2HeightInch}>
                                <Sample setChild={this.textChangeHandler.bind(this)} reqFlag={true} name={'Height_Inch' + i} label={'Height (inches)'} value={this.state.bmiData[i].inches} disable={false} style={customStyle.textFieldWrp11} length={2}  fieldType={'inch'} errMsg={'Enter valid height'} helperMsg={'Height required'}  parentDetails={{name:'inches', index : i, id : key.id}}></Sample>
                            </div>
                        </Grid>
                        <Grid xs={4} item={true}>
                            <div style={customStyle.EnrollNew2Weight}>
                                <Sample setChild={this.textChangeHandler.bind(this)} reqFlag={true} name={'Weight' + i} label={'Weight (pounds)'} value={this.state.bmiData[i].weight} disable={false} style={customStyle.textFieldWrp22} length={3}  fieldType={'num'} errMsg={'Enter valid weight'} helperMsg={'Weight required'}  parentDetails={{name:'weight', index : i, id : key.id}}></Sample>
                            </div>
                        </Grid>
                    </Grid>
                ))

        }*/

        /*finishLaterButton = <WizardButton
            variant="contained" style={customeClasses.finishButton}
            onClick={this.submitAnswers}>{i18n.t('BUTTON.FINISH_LATER')}
            </WizardButton>  */

        finishButton =<WizardButton disabled={this.state.submitValid}
            variant="contained" style={customeClasses.finishButton}
            onClick={this.submitAnswers}>{i18n.t('BUTTON.SUBMIT')}
            </WizardButton>

        let currScreen;
        let relatedQuestions = this.state.questions[this.state.count].question && this.state.questions[this.state.count].question.relatedQuestions ? this.state.questions[this.state.count].question.relatedQuestions : [];
        let relatedSubQuestions = this.state.questions[this.state.count].question && this.state.questions[this.state.count].question.relatedQuestions ? this.state.questions[this.state.count].question.relatedQuestions : [];
        
        
        
        return (
            <div style={customeClasses.wizContainer}>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
                <div style={ customStyle.w100 }>
                    <div>
                        {
                          !this.state.disqualified ?
                            <div>
                                <Grid container>
                                    <Grid item xs={12}>
                                <Typography component="p" style={customeClasses.rowText}>
                                    {/* {this.props.reEnroll? i18n.t('Eligibility.ReEnrollmentTilte'): i18n.t('Eligibility.TITLE')} */}

                                    {
                                        this.state.isAgent && this.props.reEnroll == false? 
                                        // i18n.t('Eligibility.AGENTS') 
                                        <p>We need to confirm that everyone in the family meets eligibility requirements by asking some medical questions. Some medical conditions may exclude one or more family members from sharing program membership. Some information, such as smoking, may result in increased sharing contribution amounts. Please answer these questions as accurately as possible.</p>
                                        :
                                        this.state.isAgent && this.props.reEnroll ?
                                        // i18n.t('Eligibility.REENROLLMENTTITLEAGENT')
                                        <p>Because you’ve made changes, we need to confirm that everyone 
                                            in the family meets eligibility requirements by asking some medical questions. Some medical conditions may exclude one or more family members from sharing program membership. Some information, such as smoking, may result in increased sharing contribution amounts. Please answer these questions as accurately as possible.</p>
                                        :
                                        this.state.isAgent == false && this.props.reEnroll ?
                                        
                                        i18n.t('Eligibility.ReEnrollmentTilte')
                                        :
                                        i18n.t('Eligibility.TITLE')
                                    }
                                </Typography>
                                </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12}>
                                <LinearProgress variant="determinate" classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} style={classes.progress} value={this.state.progress} />
                                </Grid>
                                </Grid>
                                
                                <Grid container>
                                    <Grid item xs={12}>
                                    <Typography variant="h6" component="h3" style={customeClasses.questionTitleText}>
                                        {this.state.questions[this.state.count].question.title}
                                    </Typography>
                                    </Grid>
                                    </Grid>

                                    <div >
                                        <div >
                                    {/* =================Question==================== */}

                                    <Grid container>
                                    <Grid item xs={12}>
                                    <div style={customeClasses.questionText}>
                                                {
                                                    this.state.questions[this.state.count].question.type === 'form' ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.count].question.question}} /> :
                                                    this.state.questions[this.state.count].question.question ? this.state.questions[this.state.count].question.question.replace('SHOW DATE', new Date()) : ''
                                                }
                                            </div>
                                    </Grid>
                                    </Grid>

                                           
                                            {/* ===================List====================== */}
                                            <Grid container>
                                            <Grid item xs={12} md={4} lg={4}>
                                            {
                                                this.state.questions[this.state.count].question && this.state.questions[this.state.count].question.subTitle ?
                                                    <div style={customeClasses.questionSubtext}>
                                                        {
                                                            this.state.questions[this.state.count].question && this.state.questions[this.state.count].question.subTitle ? this.state.questions[this.state.count].question.subTitle.map((key, index) => (
                                                                <li className="listStyle" key={index}>{key}</li>
                                                            )) : ''
                                                        }
                                                    </div> : ''
                                            }
                                            </Grid>
                                            </Grid>
                                            {/* ==================Radio button======================= */}
                                            <Grid container>
                                            <Grid item xs={12} md={4} lg={4}>
                                            <div style={customeClasses.answerText}>
                                                {answerField}
                                            </div>
                                            </Grid>
                                            </Grid>
                                            {/* ========================if yes ============================= */}
                                            <Grid container>
                                            <Grid item xs={12} md={6} lg={6}>
                                            {
                                                this.state.answerList[this.state.count] && this.state.answerList[this.state.count].answer === 'Yes' ? <div>
                                                        {
                                                            relatedQuestions.map((key, index) => (
                                                                <div key={key.id}>
                                                                    <div style={
                                                                        customStyle.elganswerCheck
                                                                    }>
                                                                        {key.question}
                                                                    </div>
                                                                    {
                                                                        key.type === 'multiselect' ? 
                                                                        <Grid container>
                                                                        <Grid item xs={12} md={8} lg={8}>
                                                                        {/* <FormControl > */}

                                                                            <div style={customStyle.checkBoxStyle}>
                                                                                {this.state.membersList.map(key => (
                                                                                    
                                                                                    <label key={key.id} value={key.id} style={{display:'block',marginBottom:'0px'}}>
                                                                                        <Checkbox
                                                                                            id='input'
                                                                                            inputProps={{
                                                                                                'aria-label': 'secondary checkbox',
                                                                                                
                                                                                            }}
                                                                                            style={{ color: '#533278'}}
                                                                                            checked={this.state.answerList[this.state.count].relatedQuestionAns[index].answer.indexOf(key.id.toString()) !== -1}
                                                                                            onChange={ event=> this.multiselectAnswerChangeHandler(event, key, index)}
                                                                                        />
                                                                                         <span style={{display:'block',marginLeft:'38px',marginTop:'-35px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>{key.firstName + ' ' + key.lastName}</span>
                                                                                    </label>
                                                                                ))}

                                                                            </div>


                                                                        {/* </FormControl>  */}
                                                                        </Grid></Grid>: ''
                                                                    }
                                                                    
                                                                    {

                                                            key.relatedSubQuestions.map((key, subindex) => (
                                                                <div key={key.id}>
                                                                    {
                                                                        key.subType === 'date' ?
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                                                <KeyboardDatePicker
                                                                                    showTodayButton
                                                                                    onBlur={()=>this.setState({dateOfTreatment:true})}
                                                                                    onMouseEnter={()=>this.setState({dateOfTrtmt:true})}
                                                                                    onMouseLeave={()=>this.setState({dateOfTrtmt:false})}
                                                                                    onCopy={this.handlerCopy}
                                                                                    onPaste={this.handlerCopy}
                                                                                    required
                                                                                    onFocus={e => e.target.blur()}
                                                                                    id="date-picker-dialog"
                                                                                    label="Date of treatment"
                                                                                    format="MM/dd/yyyy"
                                                                                    disableFuture={true}
                                                                                    style={
                                                                                     customStyle.datePickerr
                                                                                   }
                                                                                    value={this.state.answerList[this.state.count].relatedQuestionAns[index].relatedSubQuestionAns[subindex].answer}
                                                                                    onChange={(event) => this.handleDateChange(event, index, subindex)}
                                                                                    inputProps={{style:{fontSize:18,fontFamily:'Roboto, Arial, Helvetica, sans-serif',backgroundColor:'#f1f1f1', paddingLeft:14,paddingRight:14,marginTop:10,outline:0,color: !this.state.dateOfTrtmt?'black':'#533278'}}}
                                                                                    autoComplete="off"
                                                                                    InputLabelProps={{style: {fontSize:16,paddingLeft:16,paddingRight:16,paddingTop:4,color: !this.state.dateOfTreatment?'grey':'#533278'}}}
                                                                                />
                                                                                    </MuiPickersUtilsProvider> :key.subType == 'textbox' ?

                                                                                        <div>
                                                                                            <div>
                                                                                                <Sample setChild={this.textAnswerChangeHandler.bind(this)} reqFlag={true} name={'filled-required'} label={subindex == 1 ? 'Name of Physician' : 'Diagnosis,treatment and/or condition'}
                                                                                                        value={this.state.answerList[this.state.count].relatedQuestionAns[index].relatedSubQuestionAns[subindex].answer}
                                                                                                        style={customStyle.physicianTxtWrp} length={120}  fieldType={ subindex == 1 ? 'holderName' : 'treatment'} errMsg={'Enter valid name'}
                                                                                                        helperMsg={'Field is Required'}  parentDetails={{index:index,subindex:subindex,name:'Physician'}}></Sample>
                                                                                            </div>

                                                                                        </div>
                                                                                        : ''

                                                                                }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    : ''
                                            }
                                            </Grid>
                                            </Grid>
                                        </div>
                                        {/* ----------------- */}

                                        {this.state.questions[this.state.count].question.instruction !== '' &&
                                        <div style={customeClasses.w30}>
                                            <div style={customeClasses.subTitle}>{i18n.t('ENROLL_NEW.SUB_TITLE')}</div>
                                            <div style={customeClasses.instTxt}>
                                                {this.state.questions[this.state.count].question.instruction}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div style={customStyle.disqualifiedWrp}>
                                    <div style={customStyle.disqfTextWrp}>
                                        {
                                            this.state.disQualifiedData.instruction && this.state.disQualifiedData.instruction.map((key,index)=>{
                                                return(
                                                    <span key={index}>{key}</span>
                                                )
                                            })
                                        }
                                    </div>
                                    <Grid container justify="flex-end">
                    <Grid xs={12} md={8} lg={8} style={{paddingLeft:'10px',paddingRight:'25px',tableLayout:'fixed',paddingBottom:'15px',paddingTop:'15px'}} item={true}>
                    <div style={{ overflowX: "auto" }} >
                                    <div style={customStyle.disqfTableWrp}>
                                        <CommonTable quoteData={this.state.disQualifiedData.data} check={true} headerData={this.state.disQualifiedData.header} tooltip={[]} quickQuote={false} totalReq={false} />
                                    </div>
                                    </div>
                                    </Grid>
                                    </Grid>
                                    

                                </div>

                        }


                        <Modal size="lg" show={this.state.disQuailifyModal} onHide={(event) => this.hideModal(event)} backdrop="static" centered>
                            <Modal.Header style={customStyle.modal_header} closeButton>
                                <Modal.Title>Non Eligibility</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ padding: '5px' ,textAlign : 'justify',fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>
                                {
                                    this.state.loaderShow ? <Loader></Loader> : ''
                                }
                                <p>A family member has been determined as ineligible based on the information you provided. Would you like to continue checking the eligibility of the other family members? </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <WizardButton style={customStyle.m10} onClick={() => this.handleQuite()}>{i18n.t('BUTTON.QUITE')}</WizardButton>
                                <WizardButton onClick={() => this.handleContinue('BUTTON')}>{i18n.t('BUTTON.CONTINUE')}</WizardButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                    <div style={customStyle.bottomMainConatiner}>
                        <div style={customStyle.newBottomContainer}>
                            <div style={customStyle.bottomChildContainer1}>
                                    {
                                        
                                        this.state.disqualified ?
                                        <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                        <div>

                                            <WizardButton
                                                    variant="contained" style={customeClasses.button}
                                                    onClick={(event)=>this.onClickBack(event)}
                                                    >{i18n.t('BUTTON.BACK')}
                                            </WizardButton>
                                            <ProceedButton
                                                disabled={this.state.disQualifiedData.flag}
                                                variant="contained"
                                                color="primary"
                                                onClick={()=> {this.props.onClick()}}
                                                style={{width: '302px', height: '40px', marginRight : '20px',marginBottom:'20px'}}
                                            >{i18n.t('BUTTON.PROCEED_UNDERSTAND')}</ProceedButton>
                                            <ProceedButton
                                                disabled={false}
                                                variant="contained"
                                                color="primary"
                                                onClick={this.optOutFromEligibility}
                                                style={{width: '302px', height: '40px',marginBottom:'20px'}}
                                                >{this.props.isAgent ? "GO TO DASHBOARD" : i18n.t('BUTTON.NOT_PROCEED')}</ProceedButton>
                                        </div>
                                        </Grid>
                                        </Grid>
                                            :
                                            <Grid container xs={12} md={7} lg={7} >
                                        <Grid item xs={4} md={2} lg={2}>
                                          
                                                
                                                <WizardButton
                                                    variant="contained" style={this.state.count === 0 ? customeClasses.disabledButton : customeClasses.button}
                                                    disabled={this.state.count === 0 || this.state.backValid ? true : false}  /*((this.state.answerList[this.state.count].answer === 'Yes' && this.state.answerList[this.state.count].relatedQuestionAns[0].answer && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0) )*/
                                                    onClick={this.reduceProgress}>{i18n.t('BUTTON.BACK')}
                                                </WizardButton>
                                                </Grid>
                                                <Grid item xs={4} md={2} lg={2}>
                                                <WizardButton
                                                    variant="contained" style={this.state.count === this.state.questions.length ? customeClasses.disabledButton : customeClasses.button}
                                                    disabled={this.state.isValid ? false : true}
                                                    onClick={this.increaseProgress}>{i18n.t('BUTTON.NEXT')}
                                                </WizardButton>
                                                </Grid>
                                                <Grid item xs={4} md={2} lg={2}>
                                                {finishButton}
                                           
                                            </Grid>
                                            </Grid>
                                    }

                                {/*((this.state.count === this.state.questions.length-1) && (this.state.answerList[this.state.count] && this.state.answerList[this.state.count].answer) && ((this.state.answerList[this.state.count].answer === 'Yes' && this.state.answerList[this.state.count].relatedQuestionAns[0].answer && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0) || this.state.answerList[this.state.count].answer === 'No' )) ||
                                                    */}
                            </div>
                            <div style={customStyle.bottomChildContainer2}>
                                {/*<div style={customStyle.chatContainer}>
                                <CrudButton  className={'purechat-button-expand'} color="primary" aria-label="add"  style={customStyle.QuickChatBtn}>
                                                    <ForumIcon style={{fontSize : '30px'}} />
                                        </CrudButton>
                                </div>*/}
                            </div>
                        </div>
                        <div style={customStyle.newBottomContainer}>
                                {/*<div style={customStyle.needHelpContainer}>*/}
                                {/*        <div style={{ fontWeight: 'bold' }}>{i18n.t('ENROLL_FAMILY.HELP')}</div>*/}
                                {/*        <div>{i18n.t('ENROLL_FAMILY.SUPPORT')}</div>*/}
                                {/*</div>  */}
                        </div>
                    </div>
                    </div>
                     <Modal size="xs" show={this.state.modalOpen} onHide={(event) => this.optoutCancelHandler(event)} backdrop="static" centered>
                            <Modal.Header style={customStyle.modal_header} closeButton>
                                <Modal.Title>Opt-out</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ 'maxHeight': '450px', 'overflowY': 'auto',textAlign : 'justify', wordSpacing:'2px' }}>
                                {
                                    this.state.loaderShow ? <Loader></Loader> : ''
                                }
                                <span style={customStyle.QuickQtTopRightText2}>{i18n.t('ENROLLMENT.TITLE')}</span>
                                <form style={{marginTop : '10px'}}>
                                    <div style={{flexGrow : 1}}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl variant="filled" style={{width: '100%', height: '56px', marginRight: '21px'}}>
                                                    <CustomTextField select variant='filled' label='Reason for opting-out ' id="demo-simple-select-filled" value={this.state.optReason} onChange={(event) => this.optingReasonChangeHandler(event)} required>
                                                        {
                                                            this.state.optReasonList.map((key, index) => (
                                                                <MenuItem key={key.id} name={key.reasondCode} value={key.id}>{key.reasondCode}</MenuItem>

                                                            ))
                                                        }
                                                    </CustomTextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CustomTextField id="filled-required" label="Other reason" margin="normal" variant="filled" autoComplete="off" style={{width: '100%', margin: 0}} disabled={this.state.disOtReason} value={this.state.otherReason} onChange={(event) => this.otherOptingReasonChangeHandler(event)} required={false} helperText= {this.state.errorText} error={this.state.isValidopt} InputLabelProps={{style: {color:this.state.isValidopt?'#FA1515':''}}}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </form>
                                {/*<form>
                                    <div style={{ display: 'flex', width: '100%',marginTop:'10px' }}>
                                        <div>
                                            <FormControl variant="filled" style={{width: '223px', height: '56px', marginRight: '21px'}}>
                                                <CustomTextField select variant='filled' label='Reason for opting-out ' id="demo-simple-select-filled" value={this.state.optReason} onChange={(event) => this.optingReasonChangeHandler(event)} required>
                                                    {
                                                        this.state.optReasonList.map((key, index) => (
                                                            <MenuItem key={key.id} name={key.reasondCode} value={key.id}>{key.reasondCode}</MenuItem>

                                                        ))
                                                    }
                                                </CustomTextField>
                                            </FormControl>
                                        </div>
                                        <div style={{ width: '100%' }}>

                                        </div>
                                    </div>
                                </form>*/}

                            </Modal.Body>
                            <Modal.Footer>
                                <WizardButton onClick={(event) => this.optoutCancelHandler(event)} style={customStyle.m10}>
                                    {i18n.t('BUTTON.CANCEL')}
                                </WizardButton>
                                <WizardButton disabled={this.state.formValid} onClick={this.optoutSubmitHandler} style={customStyle.m10}>
                                    {i18n.t('BUTTON.SUBMIT')}
                                </WizardButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal size="lg" show={this.state.msgModal} onHide={(event) => this.hideMsgModal(event)}>
                            <Modal.Header style={customStyle.modal_header} closeButton>
                                <Modal.Title>Message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ 'maxHeight': '450px', 'overflowY': 'auto',textAlign : 'justify', wordSpacing:'1px' }}>
                                {
                                    this.state.loaderShow ? <Loader></Loader> : ''
                                }
                                <div style={{display : 'inline-block', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>
                                    <div>We regret that Universal HealthShare is currently unable to meet your family's health and wellness care. We would love to see how we can support you. Please call our number 800-921-4505 and talk to one of our representatives. Or, you can chat with a live agent on our website :</div>
                                    <a href="https://www.universalhealthfellowship.org" target="_blank" style={{color:'#533278',fontWeight:'bold', paddingLeft : '3px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>https://www.universalhealthfellowship.org</a>.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <WizardButton onClick={(event) => this.hideMsgModal(event)} style={customStyle.m10}>
                                    {i18n.t('BUTTON.OK')}
                                </WizardButton>
                            </Modal.Footer>
                        </Modal>
                   
            </div>
        );
    }
};

export default withStyles(styles)(Eligibility);
